<template>
  <div class="modify" ref="score">
    <div class="table2"></div>
    <div class="title">修改密码</div>
    <div class="content">
      <el-form label-width="120px">
        <el-form-item label="绑定手机号：" class="item">
          <!-- <el-input v-model="form.phone"></el-input> -->
          <div>{{info.phone|spl}}</div>
        </el-form-item>
        <el-form-item label="设置新密码："  class="item">
          <el-input
            v-model="form.password"
            placeholder="请输入8位字母和数字"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="重新输入密码：" class="item">
          <el-input
            v-model="form.repassword"
            placeholder="请输入8位字母和数字"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="手机验证码：" class="item">
          <el-input v-model="form.verify" placeholder="请输入验证码"></el-input>
          <el-button class="get" @click="getpushSms" v-show="show">获取验证码</el-button>
          <el-button class="get1" v-show="!show">{{count}}秒</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn">
      <el-button class="submit" @click="getresetPsw">确定</el-button>
      <el-button class="goout">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        token:'',
        verify:'',
        password:'',
        repassword:'',
        type:2,
      },
      info:{
          phone:''
      },
      show:true,
      count:'',
      timer:null,
    }
  },
  filters:{
    // 手机号脱敏
    spl(val){
      if(null != val && val != undefined){
	        var pat=/(\d{3})\d*(\d{4})/;
	        return val.replace(pat,'$1****$2');
	    } else {
	        return "";
	    }
    }
  },
  created(){
    this.judInfo()
    this.form.token = sessionStorage.getItem('token')
  },
  mounted () {
    // if(this.$refs.score){
    //   this.$nextTick(() => {
    //     // 赛事列表最小高度
    //     let height = document.querySelector(".header").clientHeight;
    //     document.querySelector(".table2").style.height = height + "px";
    //     this.$refs.score.style.minHeight = document.querySelector("body").clientHeight - height + "px" ;
    //   });
    // }
  },
  methods: {
    // 获取验证码
    getpushSms(){
      const TIME_COUNT = 60;
      if(!this.timer){
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(()=>{
          if(this.count > 0 && this.count <= TIME_COUNT){
            this.count --;
          }else{
            this.show = true;
            clearInterval(this.timer)
            this.timer = null;
          }
        },1000)
      }
      let data = {
        mobile:this.info.phone
      }
      this.$api.pushSms(data).then(({data:{code}})=>{
        if(code===1) this.$message.success('发送成功')
      })
    },
    // 修改密码
    getresetPsw(){
      this.$confirm('确认修改密码吗？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(()=>{
        let formdata = new FormData()
         formdata.append('phone',this.info.phone)
        for(let i in this.form){
          formdata.append(i,this.form[i])
        }
        this.$api.resetPsw(formdata).then(({data:{code}})=>{
          if(code===1) this.$message.success('密码修改成功')
        })
      }).catch(()=>{
        this.$message({
          message:'修改密码失败'
        })
      })

      // let formdata = new FormData()
      // for(let i in this.form){
      //   formdata.append(i,this.form[i])
      // }
      // this.$api.resetPsw(formdata).then(({data:{code}})=>{
      //   if(code===1) this.$message.success('密码修改成功')
      // })
    },
    judInfo () {
      let data2 = {
        token: sessionStorage.getItem("token"),
      };
      this.$api.getinfo({ params: data2 }).then(({ data: { data } }) => {
        this.info = data;
        this.$store.state.form = data
      });
    },
  }
}
</script>
<style lang='less' scoped>
@media screen and (max-width: 1030px) {
  .modify{
    min-height: 1128px !important;
  }
}
@media screen and (max-width:1000px) {
  .modify{
    min-height: 1128px !important;
  }
}
.modify {
  min-height: 678px;
  .title {
    color: #000;
    padding-left: 20px;
    font-size: 14px;
    margin: 0 0 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  .content {
    width: 60%;
    margin: 0 auto;
    .get {
      background: #4e80f5;
      color: #fff;
      border-radius: 0 4px 4px 0;
      border: 0;
    }
    .get1{
      background: #e4e4e4;
      color: #000;
      border-radius: 0 4px 4px 0;
      border: 0;
    }
  }
  .el-dropdown {
    width: 30%;
  }
  .btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .submit {
      background: rgb(78, 128, 245);
      color: #fff;
      width: 120px;
    }
    .goout {
      background: rgb(228, 228, 228);
      color: #000;
      width: 120px;
    }
  }
  .flex {
    display: flex;
    padding: 20px 20px;
    .left {
      width: 100px;
    }
  }
  .item {
    /deep/.el-form-item__content {
      display: flex;
    }
  }
}
</style>