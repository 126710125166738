<template>
  <div class="index">
    <!-- 头部 -->
    <header class="header border">
      <!-- 左侧标题 logo -->
      <div class="left-header border" @click="$router.push('list')">
        <div class="limage">
          <img src="@/assets/image/logo.png" alt="" />
        </div>
        <h3 style="color: #fff">赛事评审系统</h3>
      </div>

      <!-- 右侧人物 头像 -->
      <div class="right-header border">
        <el-dropdown :hide-on-click="false" @command="handleCommand">
          <div class="right">
            <div class="rimage">
              <img :src="this.$store.state.form.avatar==''?imgDefault:$showUrl + this.$store.state.form.avatar" alt="" />
            </div>
            <div style="color: #fff; font-size: 14px" class="el-dropdown-link">
              {{ this.$store.state.form.nickname }}
            </div>
          </div>
          <div class="select">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="usercenter">用户中心</el-dropdown-item>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      imgDefault:require('@/assets/image/head.png')
    };
  },
  mounted() {
    this.judInfo();
  },
  methods: {
    // 下拉
    handleCommand(command) {
      if (command == "usercenter") {
        this.$router.push("centre");
      } else if (command == "logout") {
        this.$confirm("确认退出登录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
            sessionStorage.clear();
            this.$message({
              message: "退出登录",
            });
            this.$router.push("/");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出登录",
            });
          });
      }
    },
    // 评委详细信息
    judInfo() {
      let data2 = {
        token: sessionStorage.getItem("token"),
        type: 2,
      };
      this.$api.getinfo({ params: data2 }).then(({ data: { data } }) => {
        this.name = data.nickname;
        this.$store.state.form = data;
      });
    },
  },
};
</script>
<style lang='less' scoped>
.index {
  width: 100%;
  height: 100%;
  .header {
    background: #4e80f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    margin-top: 16px;
    position: fixed;
    top: -16px;
    left: 0;
    z-index: 1000;
    width: 100%;
    .left-header {
      display: flex;
      height: 100%;
      align-items: center;
      img {
        margin-right: 20px;
        width: 40px;
        height: 40px;
      }
    }
    .right-header {
      img {
        margin-right: 20px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
      .select {
        position: absolute;
        right: 0;
        background: #fff;
        box-shadow: 0 0 2px #eee;
        padding: 4px 0;
        display: none;
        border-radius: 4px;
        z-index: 99;
        div {
          line-height: 40px;
          width: 120px;
          text-align: center;
        }
        div:first-child {
          border-bottom: 1px solid rgb(218, 218, 218);
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .right-header:hover .select {
      display: block;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
