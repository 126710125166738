<template>
  <div class="reset">
    <div class="index">
    <!-- 头部 -->
    <header class="header border">
      <!-- 左侧标题 logo -->
      <div class="left-header border" @click="$router.push('list')">
        <div class="limage">
          <img src="@/assets/image/logo.png" alt="" />
        </div>
        <h3 style="color: #fff">赛事评审系统</h3>
      </div>

      <!-- 右侧人物 头像 -->
      <div class="right-header border">
        <span @click="backlogin">返回登录</span>
      </div>
    </header>
  </div>
    <div class="psw">
      <div class="title">
        <div>重置密码</div>
      </div>
      <el-form label-width="100px">
        <el-form-item label="手机号：">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="设置新密码：">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="手机验证码：">
          <el-input v-model="form.verify"></el-input>
          <el-button v-show="show" @click="getpushSms" style="background:#4e80f5;color:#fff;border:0;">获取验证码</el-button>
          <el-button v-show="!show" style="background:#e4e4e4;color:#000;border:0;">{{count}}秒</el-button>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button class="submit" @click="getbackpsw">确定</el-button>
        <el-button class="goout" @click="backlogin">返回</el-button>
      </div>
      <!-- <div class="txt" >返回登录</div> -->
    </div>
    <HelloWorld class="text"></HelloWorld>
  </div>
</template>
<script>
import HelloWorld from '@/components/HelloWorld'
export default {
  components: { HelloWorld },
  data () {
    return {
      form: {
        phone: '',//手机号码
        verify: '',//验证码
        password: '',//密码
        type:2
      },
      show:true,
      count:'',
      timer:null,
    }
  },
  methods: {
    getpushSms () {
      const TIME_COUNT = 60;
      if(!this.timer){
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(()=>{
          if(this.count > 0 && this.count <= TIME_COUNT){
            this.count --;
          }else{
            this.show = true;
            clearInterval(this.timer)
            this.timer = null;
          }
        },1000)
      }
      var form = new FormData()
      form.append('mobile', this.form.phone)
      this.$api.pushSms(form).then(res => {
        this.$message.success('验证码已发送')
      })
    },
    async getbackpsw () {
      var form = new FormData()
      for (let i in this.form) {
        form.append(i, this.form[i])
      }
     let {data} = await this.$api.backpsw(form)
     
     try{
       if(data.code===1){
         this.$message.success('密码修改成功')
         this.$router.push('/')
         return
       }
       this.$message.error(data.msg == '' ? '密码修改失败请重新设置密码' : data.msg)
     }catch(err){
       console.log(err);
       this.$message.error('请检查网络连接')
     }
    },
    backlogin(){
      this.$router.push('/')
    },
  }
}
</script>
<style lang='less' scoped>
.reset {
  height: 100%;
  position: relative;
  background: #eee;
}
.index {
  width: 100%;
  height: 100%;
  .header {
    background: #4e80f5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    margin-top: 16px;
    position: fixed;
    top: -16px;
    left: 0;
    z-index: 1000;
    width: 100%;
    .left-header {
      display: flex;
      height: 100%;
      align-items: center;
      img {
        margin-right: 20px;
        width: 40px;
        height: 40px;
      }
    }
    .right-header {
      span{
        color: #fff;
        // font-size: 20px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .right-header:hover .select {
      display: block;
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.psw {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 30px 120px;
  border-radius: 20px;
  .title {
    text-align: center;
    margin: 0 0 40px;
    font-size: 22px;
  }
  /deep/.el-form-item__content {
    display: flex;
  }
  .btn {
    display: flex;
    justify-content: center;
    .submit {
      background: rgb(78, 128, 245);
      color: #fff;
      width: 100px;
    }
    .goout {
      background: rgb(228, 228, 228);
      color: #000;
      width: 100px;
      border: 0;
    }
  }
  .txt {
    text-align: center;
    color: rgb(78, 128, 245);
    font-size: 14px;
    margin: 40px 0 0;
    cursor: pointer;
  }
}

.text {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.el-form-item__label{
  text-align: left;
}
</style>