import { get, post, patch, deletes } from './axios.js'
const API = {
  // 评委登录
  login (data) {
    return post('/api/member/login', data)
  },
  register(data){
    return post('/api/member/register',data)
  },
  upavtar(data){
    return post('/api/judges/setJudAvatar',data)
  },
  // 评委个人信息
  getinfo (data) {
    return get('/api/judges/getJudDetail', data)
  },
  // 个人基本信息
  user (data) {
    return get('/api/member/getUserBriefly', data)
  },
  // 评委信息审核
  setComment (data) {
    return post('/api/judges/setJudComment', data)
  },
  // 获取评审赛事
  judeMatch(data){
    return get('/api/judges/getJudgeMatch',data)
  },
  // 评审进度
  gress(data){
    return post('/api/judges/progress',data)
  },
  // 获取学历列表 
  education () {
    return get('/api/member/getEducation')
  },
  // 行业领域列表
  industry(){
    return get('/api/setting/getIndustry')
  },
  // 项目阶段列表
  phase(){
    return get('/api/setting/getProjectPhase')
  },
  // 地区数据
  prov(){
    return get('/api/address/getProvList')
  },
  // 地址列表
  address(){
    return get('/api/address/getAddress')
  },
  // 公司规模
  scale(){
    return get('/api/setting/getBusScale')
  },
  //团队规模
  teamscale(){
    return get('/api/setting/getTeamScale')
  },
  // 身份类别
  ident(){
    return get('/api/setting/getIdent')
  },
  // 投资额度
  stment(){
    return get('/api/setting/getInvestment')
  },
  // 投资币种
  amountType(){
    return get('/api/setting/getAmountType')
  },
  // 投票
  vote(data){
    return post('/api/judges/judgeVote',data)
  },
  // 推荐
  tuijian(data){
    return post('/api/judges/recommend',data)
  },
  // 取消推荐
  deltuijian(data){
    return post('/api/judges/cancelRecommend',data)
  },
  // 保存评分
  scoresCommit(data){
    return post('/api/judges/scoresCommit',data)
  },
  // 提交评审
  pushvalue (data) {
    return post('/judges/pushJudValues', data)
  },
  // 获取评分标准
  getRule (data) {
    return get('/api/judges/getRule', data)
  },
  // 评委评审项目列表-未评审新
  xcxNull (data) {
    return get('/api/judges/getJudWaitProjectV20220228', data)
  },
  // 评委评审项目列表-已评审新
  scxSuc (data) {
    return get('/api/judges/getJudSucProjectV20220228', data)
  },
  
  // 详情页面
  playdetail(data){
    return post('/api/project/formDetail',data)
  },
  // 评分规则
  Rule(data){
    return get('/api/judges/getRuleIndex',data)
  },
  // 提交评分
  scores(data){
    return post('/api/judges/scoresPush',data)
  },
  // 发送验证码
  pushSms (data) {
    return post('/api/sms/pushSms', data)
  },
  // 重置密码
  backpsw (data) {
    return post('/api/security/getbackPWD', data)
  },
  // 修改密码
  resetPsw(data){
    return post('/api/security/resetPWD',data)
  },
  // 上传文件
  upload(data){
    return post('/api/upload/push',data)
  },
  // 地址字符串
  addstr(data){
    return post('/api/address/getAddressStr',data)
  },
  //投票并打分，保存
  recommendAndScoresCommit(data){
    // return post('/api/judges/recommendAndScoresCommit',data)
    return post('/api/judges/voteAndScoresCommit',data)
  },
  //投票并打分，提交
  recommendAndScoresPush(data){
    // return post('/api/judges/recommendAndScoresPush',data)
    return post('/api/judges/voteAndScoresPush',data)
  },
  // 获取确认单
  getReviewList(data){
    return post('/api/judges/getReviewList',data)
  },
};
export default API