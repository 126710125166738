<template>
  <div class="imgurl">
    <img class="img" :src="$showUrl + url" alt="">
  </div>
</template>
<script>
export default {
  data(){
    return {
      url:''
    }
  },
  mounted(){
    this.url = this.$route.query.url
  }
}
</script>
<style lang="less" scoped>
.imgurl{
  height: 100vh;
  position: relative;
  background-color: rgb(102, 102, 102);
  .img{
    max-width: 800px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>