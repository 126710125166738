import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import Login from '../views/Login.vue'
import list from '@/views/list.vue'
import psList from '@/views/psList.vue'
import centre from '@/views/centre.vue'
import reset from '@/views/reset'
import score from '@/views/score'
import imgurl from '@/views/imgurl'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },{
    path:'/index',
    component:index,
    children:[
      {
        path:'list',
        component:list
      },
      {
        path:'psList',
        component:psList
      },
      {
        path:'centre',
        component:centre
      },
      {
        path:'score',
        component:score
      }
    ]
  },
  {
    path: '/reset',
    component: reset
  },
  {
    path: '/imgurl',
    component: imgurl
  }
]

const router = new VueRouter({
  routes
})

export default router
